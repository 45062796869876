<template>
  <div>
    <el-row style="margin-top: 10px">
      <el-col>
        <el-card>
          <div slot="header">
          <svg-icon class="gayhub" icon-class="github" style="float:left" @click="goToSubWebModify" ></svg-icon>
          <svg-icon class="gayhub" icon-class="github" style="float:left;margin-left: 15px" @click="goToSubConverter" ></svg-icon>
          <svg-icon class="channel" icon-class="sshkit" style="float:right;margin-left:15px" @click="gotoSSHKit" ></svg-icon>
          <svg-icon class="youguan" icon-class="howdy" style="float:right;margin-left:15px" @click="gotoHowdy" ></svg-icon>
          <svg-icon class="bilibili" icon-class="fastssh" style="float:right;margin-left:15px" @click="gotoFastSSH" ></svg-icon>
          <div style="text-align:center;font-size:15px">Config Generator</div>
          </div>
          
          <el-container>
            <el-form :model="form" label-width="120px" label-position="top" style="width: 100%">
              <el-form-item label="Input Link Config :">
                <el-input
                  v-model="form.sourceSubUrl"
                  type="textarea"
                  :autosize="{ minRows: 5}"
                  placeholder="Paste link config yg sudah di buat di sini, multi links dipisahkan dengan enter atau dengan tanda |"
                ></el-input>
              </el-form-item>
              <el-checkbox v-model="form.tfo" label="VMESS WS REVERSE" border></el-checkbox>
              <el-form-item label="Pilih Jenis Config :">
                  <el-select
                    v-model="form.remoteConfig"
                    allow-create
                    filterable
                    placeholder="Wajib pilih !!!"
                    style="width: 100%"
                  >
                    <el-option-group
                      v-for="group in options.remoteConfig"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-option-group>
                    <el-button slot="append" @click="gotoRemoteConfig" icon="el-icon-link">Configuration example</el-button>
                  </el-select>
              </el-form-item>
              <el-form-item label="Format Proxy:">
              <el-select
                  v-model="form.customBackend"
                  allow-create
                  filterable
                  placeholder="Pilih jenis format proxy"
                  style="width: 100%"
                >
                  <el-option v-for="(v, k) in options.customBackend" :key="k" :label="k" :value="v"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Custom Nama Config :">
                    <el-input
                        v-model="form.filename"
                        clearable
                        placeholder="Input nama file config misal config.yaml, config.txt dll trs klik tombol download"
                    >
                    </el-input>
              </el-form-item>
              
              <div style="margin-top: 30px"></div>
                  
              <el-form-item class="eldiy" label-width="0px">
                  <el-row type="flex">
                      <el-button
                        style="width: 180px"
                        type="danger"
                        @click="downloadFile"
                        icon="el-icon-printer"
                        :disabled="form.filename.length === 0 || form.sourceSubUrl.length === 0"
                        >Download File Config</el-button>
                      <el-popover placement="bottom" v-model="form.extraset">
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.emoji" label="Tambah Emoji"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.udp" label="Enable UDP"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.appendType" label="Tambah protocol di depan nama proxy"></el-checkbox></el-col>                 
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.expand" label="Uncheck untuk versi rule provider"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.scv" label="Skip certificate verification"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.sort" label="Urutkan Proxy"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.new_name" label="Support Clash Premium"></el-checkbox></el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12"><el-checkbox v-model="form.tfo" label="Enable TFO"></el-checkbox></el-col>
                      </el-row>
                      <el-button 
                        slot="reference"
                        icon="el-icon-setting"
                        >Opsi Lanjutan
                      </el-button>
                    </el-popover>
                    <el-checkbox v-model="form.nodeList" label="Generate proxy-provider" border></el-checkbox>
                  </el-row>
              </el-form-item>
              <div style="margin-top: 30px"></div>
              <el-divider content-position="center">
                <el-button 
                 type="zhuti"
                 @click="change">
                 <i id="rijian" class="el-icon-sunny" ></i>  
                 <i id="yejian" class="el-icon-moon" ></i>    
                 </el-button>
              </el-divider>
              <el-form-item label="Link panjang :">
                <el-input class="copy-content" disabled v-model="customSubUrl">
                  <el-button
                    slot="append"
                    v-clipboard:copy="customSubUrl"
                    v-clipboard:success="onCopy"
                    ref="copy-btn"
                    icon="el-icon-document-copy"
                  >copy</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="Link pendek :">
                <el-input class="copy-content" disabled v-model="customShortSubUrl">
                  <el-button
                    slot="append"
                    v-clipboard:copy="customShortSubUrl"
                    v-clipboard:success="onCopy"
                    ref="copy-btn"
                    icon="el-icon-document-copy"
                  >copy</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label-width="0px" style="margin-top: 40px; text-align: center">
                <el-button
                  style="width: 145px"
                  type="danger"
                  @click="makeUrl"
                  icon="el-icon-link"
                  :disabled="form.sourceSubUrl.length === 0"
                >Link Panjang</el-button>
                <el-button
                  style="width: 145px"
                  type="danger"
                  @click="makeShortUrl"
                  icon="el-icon-link"
                  :loading="loading"
                  :disabled="customSubUrl.length === 0"
                >Link Pendek</el-button>
              </el-form-item>
              <el-form-item label-width="0px" style="text-align: center">
                <el-button
                  style="width: 300px"
                  type="primary"
                  icon="el-icon-service"
                  @click="gototelegram"
                >Telegram Group</el-button>
              </el-form-item>
              <el-form-item label-width="0px" style="text-align: center">
                <el-button
                  style="width: 145px"
                  type="primary"
                  @click="clashInstall"
                  icon="el-icon-connection"
                  :disabled="customSubUrl.length === 0"
                >One-click Import</el-button>
                <el-button
                  style="width: 145px"
                  type="primary"
                  @click="dialogUploadConfigVisible = true"
                  icon="el-icon-upload2"
                  :loading="loading"
                >Upload Custom</el-button>
                </el-form-item>
              <el-form-item label-width="0px" style="text-align: center">
                <el-button
                  style="width: 300px"
                  type="success"
                  icon="el-icon-mobile"
                  @click="gotocfwdownload"
                >Clash For Magisk mod by @Tamarindus_indica</el-button>
              </el-form-item>
              <el-form-item label-width="0px" style="text-align: center">
                <el-button
                  style="width: 300px"
                  type="success"
                  icon="el-icon-mobile"
                  @click="gotocfadownload"
                >Clash For Android</el-button>
              </el-form-item>
            </el-form>
          </el-container>
        </el-card>
      </el-col>
    </el-row>
    
    <el-dialog
      :visible.sync="dialogUploadConfigVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <div slot="title">
        Remote config upload | Contoh ->
        <el-popover trigger="hover" placement="right" style="margin-left: 10px">
          <el-link type="primary" :href="sampleConfig" target="_blank" icon="el-icon-info">Tutorial dan contoh custom config</el-link>
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
      </div>
      <el-form label-position="left">
        <el-form-item prop="uploadConfig">
          <el-input
            v-model="uploadConfig"
            type="textarea"
            :autosize="{ minRows: 15, maxRows: 15}"
            maxlength="10000"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="uploadConfig = ''; dialogUploadConfigVisible = false">Batal</el-button>
        <el-button
          type="primary"
          @click="confirmUploadConfig"
          :disabled="uploadConfig.length === 0"
        >Upload</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
const subwebmodify = process.env.VUE_APP_SUBWEBMODIFY
const subconverter = process.env.VUE_APP_SUBCONVERTER
const remoteConfigSample = process.env.VUE_APP_SUBCONVERTER_REMOTE_CONFIG
const gayhubRelease = process.env.VUE_APP_BACKEND_RELEASE
const defaultBackend = process.env.VUE_APP_SUBCONVERTER_DEFAULT_BACKEND + '/sub?'
const shortUrlBackend = process.env.VUE_APP_MYURLS_DEFAULT_BACKEND + '/short'
const configUploadBackend = process.env.VUE_APP_CONFIG_UPLOAD_BACKEND + '/config/upload'
const sshkitlink = process.env.VUE_APP_SSHKIT_LINK
const howdylink = process.env.VUE_APP_HOWDY_LINK
const fastsshlink = process.env.VUE_APP_FASTSSH_LINK
const cfalink = process.env.VUE_APP_CFA_LINK
const cfwlink = process.env.VUE_APP_CFW_LINK
const telegramlink = process.env.VUE_APP_TELEGRAM_LINK

export default {
  data() {
    return {
      backendVersion: "",

      // Whether it is PC side
      isPC: true,

      options: {
        clientTypes: {
          Clash: "clash",
        },
        shortTypes: {
        },
        customBackend: {
          "Format Samping":"https://sub.bonds.id/sub?",
          "Format Bawah":"https://sub.bonds.id/sub2?",
        },
        backendOptions: [
          { value: "https://sub.bonds.id/sub?" },
          { value: "https://sub.bonds.id/sub2?" },
        ],
        remoteConfig:
        [
            {
                label: "Clash Standard",
                options:
                [
                    {
                        label: "Standard - Fake IP",
                        value: "base/database/config/standard/standard_fake.ini"
                    },
                    {
                        label: "Standard - Redir Host",
                        value: "base/database/config/standard/standard_redir.ini"
                    }
                ]
            },
            {
                label: "Clash For Magisk mod by @Tamarindus_indica",
                options:
                [
                    {
                        label: "Clash For Magisk - Fake IP",
                        value: "base/database/config/cfm/cfm_fake.ini"
                    },
                    {
                        label: "Clash For Magisk - Redir Host",
                        value: "base/database/config/cfm/cfm_redir.ini"
                    }
                ]
            },
            {
                label: "Clash Group",
                options:
                [
                    {
                        label: "Best Ping - Fake IP",
                        value: "base/database/config/custom/groups/urltest_fake.ini"
                    },
                    {
                        label: "Load Balance - Fake IP",
                        value: "base/database/config/custom/groups/loadbalance_fake.ini"
                    },
                    {
                        label: "Fallback - Fake IP",
                        value: "base/database/config/custom/groups/fallback_fake.ini"
                    },
                    {
                        label: "All Group - Fake IP",
                        value: "base/database/config/custom/groups/allgroup_fake.ini"
                    },
                    {
                        label: "Best Ping - Redir Host",
                        value: "base/database/config/custom/groups/urltest_redir.ini"
                    },
                    {
                        label: "Load Balance - Redir Host",
                        value: "base/database/config/custom/groups/loadbalance_redir.ini"
                    },
                    {
                        label: "Fallback - Redir Host",
                        value: "base/database/config/custom/groups/fallback_redir.ini"
                    },
                    {
                        label: "All Group - Redir Host",
                        value: "base/database/config/custom/groups/allgroup_redir.ini"
                    }
                ]
            },
            {
                label: "Clash Adblock",
                options:
                [
                    {
                        label: "Standard + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/adblock_standard_fake.ini"
                    },
                    {
                        label: "Best Ping + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/adblock_urltest_fake.ini"
                    },
                    {
                        label: "Load Balance + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/adblock_loadbalance_fake.ini"
                    },
                    {
                        label: "Fallback + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/adblock_fallback_fake.ini"
                    },
                    {
                        label: "All Group + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/adblock_allgroup_fake.ini"
                    },
                    {
                        label: "Standard + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/adblock_standard_redir.ini"
                    },
                    {
                        label: "Best Ping + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/adblock_urltest_redir.ini"
                    },
                    {
                        label: "Load Balance + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/adblock_loadbalance_redir.ini"
                    },
                    {
                        label: "Fallback + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/adblock_fallback_redir.ini"
                    },
                    {
                        label: "All Group + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/adblock_allgroup_redir.ini"
                    }
                ]
            },
            {
                label: "Clash For Magisk Group",
                options:
                [
                    {
                        label: "CFM - Best Ping - Fake IP",
                        value: "base/database/config/custom/groups/cfm_urltest_fake.ini"
                    },
                    {
                        label: "CFM - Load Balance - Fake IP",
                        value: "base/database/config/custom/groups/cfm_loadbalance_fake.ini"
                    },
                    {
                        label: "CFM - Fallback - Fake IP",
                        value: "base/database/config/custom/groups/cfm_fallback_fake.ini"
                    },
                    {
                        label: "CFM - All Group - Fake IP",
                        value: "base/database/config/custom/groups/cfm_allgroup_fake.ini"
                    },
                    {
                        label: "CFM - Best Ping - Redir Host",
                        value: "base/database/config/custom/groups/cfm_urltest_redir.ini"
                    },
                    {
                        label: "CFM - Load Balance - Redir Host",
                        value: "base/database/config/custom/groups/cfm_loadbalance_redir.ini"
                    },
                    {
                        label: "CFM - Fallback - Redir Host",
                        value: "base/database/config/custom/groups/cfm_fallback_redir.ini"
                    },
                    {
                        label: "CFM - All Group - Redir Host",
                        value: "base/database/config/custom/groups/cfm_allgroup_redir.ini"
                    }
                ]
            },
            {
                label: "Clash For Magisk Adblock",
                options:
                [
                    {
                        label: "CFM - Standard + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/cfm_adblock_standard_fake.ini"
                    },
                    {
                        label: "CFM - Best Ping + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/cfm_adblock_urltest_fake.ini"
                    },
                    {
                        label: "CFM - Load Balance + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/cfm_adblock_loadbalance_fake.ini"
                    },
                    {
                        label: "CFM - Fallback + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/cfm_adblock_fallback_fake.ini"
                    },
                    {
                        label: "CFM - All Group + Adblock - Fake IP",
                        value: "base/database/config/custom/adblocks/cfm_adblock_allgroup_fake.ini"
                    },
                    {
                        label: "CFM - Standard + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/cfm_adblock_standard_redir.ini"
                    },
                    {
                        label: "CFM - Best Ping + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/cfm_adblock_urltest_redir.ini"
                    },
                    {
                        label: "CFM - Load Balance + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/cfm_adblock_loadbalance_redir.ini"
                    },
                    {
                        label: "CFM - Fallback + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/cfm_adblock_fallback_redir.ini"
                    },
                    {
                        label: "CFM - All Group + Adblock - Redir Host",
                        value: "base/database/config/custom/adblocks/cfm_adblock_allgroup_redir.ini"
                    }
                ]
            }
        ]
      },
      form: {
        sourceSubUrl: "",
        clientType: "clash",
        customBackend: "https://sub.bonds.id/sub2?",
        shortType: "https://d1.mk/short",
        remoteConfig: "base/database/config/standard/standard_redir.ini",
        excludeRemarks: "",
        includeRemarks: "",
        filename: "",
        rename: "",
        emoji: false,
        nodeList: false,
        extraset: false,
        sort: false,
        udp: true,
        tfo: false,
        expand: false,
        scv: true,
        fdn: false,
        appendType: false,
        insert: false, // Whether to insert the node of the default subscription, corresponding to the configuration item insert_url
        new_name: true, // Whether to use the Clash new field

        // tpl Custom function
        tpl: {
          surge: {
            doh: false // dns query whether to use DoH
          },
          clash: {
            doh: false
          }
        }
      },

      loading: false,
      customSubUrl: "",
      customShortSubUrl: "",

      dialogUploadConfigVisible: false,
      uploadConfig: "",
      uploadPassword: "",
      sampleConfig: remoteConfigSample
    };
  },
  created() {
    document.title = "Clash Config Generator";
    this.isPC = this.$getOS().isPc;
  },
  mounted() {
    this.notify();
    this.form.clientType = "clash";
    this.anhei();
    let lightMedia = window.matchMedia('(prefers-color-scheme: light)');
    let darkMedia = window.matchMedia('(prefers-color-scheme: dark)');
    let callback = (e) => {
    if (e.matches) {
       this.anhei();
       }
    };
    if (typeof darkMedia.addEventListener === 'function'|| typeof lightMedia.addEventListener === 'function') {
       lightMedia.addEventListener('change', callback);
       darkMedia.addEventListener('change', callback);
    } //Monitor system theme and switch automatically!
  },
  methods: {
    anhei() {
      const getLocalTheme = window.localStorage.getItem("localTheme");
      const lightMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: light)'); 
      const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)'); 
      if (getLocalTheme) {
        document.getElementsByTagName('body')[0].className = getLocalTheme;
      } //Read localstorage，The highest priority!
      else if (getLocalTheme == null || getLocalTheme == "undefined" || getLocalTheme == "") {
      if (new Date().getHours() >= 19 || new Date().getHours() < 7) {
        document.getElementsByTagName('body')[0].setAttribute('class', 'dark-mode');  
      } 
      else {
        document.getElementsByTagName('body')[0].setAttribute('class', 'light-mode');  
      } //Judging by the current time, it is used to deal with browsers that do not support media variable queries such as QQ
      if (lightMode && lightMode.matches) { 
        document.getElementsByTagName('body')[0].setAttribute('class', 'light-mode');
      } 
      if (darkMode && darkMode.matches) { 
        document.getElementsByTagName('body')[0].setAttribute('class', 'dark-mode');
      } //Judge the current theme according to the window theme!
     }  
    },  
    change() {
      var zhuti = document.getElementsByTagName('body')[0].className;
      if (zhuti === 'light-mode'){
      document.getElementsByTagName('body')[0].setAttribute('class', 'dark-mode');
      window.localStorage.setItem('localTheme','dark-mode');
      }
      if (zhuti === 'dark-mode'){
      document.getElementsByTagName('body')[0].setAttribute('class', 'light-mode');
      window.localStorage.setItem('localTheme','light-mode');
      }
    },
    notify() {
      this.$notify({
        title: "Welcome",
        type: "warning",
        position: 'top-left',
        customClass: 'msgbox',
        message: (
          "Selamat datang di Howdy.id Config Generator. Untuk bikin akun silakan click LOGO di sebelah kanan atas. Untuk download file wajib isi di Custom Nama Config misal config.yaml, config.txt"
        )
      });
    },
    onCopy() {
      this.$message.success("Copied!");
    },
    goToSubWebModify() {
      window.open(subwebmodify);
    },
    goToSubConverter() {
      window.open(subconverter);
    },
    gotoSSHKit() {
      window.open(sshkitlink);
    },
    gotoGayhub() {
      window.open(gayhubRelease);
    },
    gotoFastSSH() {
      window.open(fastsshlink);    
    },
    gotoHowdy() {
      window.open(howdylink);    
    },
    gotocfadownload() {
      window.open(cfalink);
    },
    gotocfwdownload() {
      window.open(cfwlink);
    },
    gototelegram() {
      window.open(telegramlink);
    },
    gotoRemoteConfig() {
      window.open(remoteConfigSample);
    },
    clashInstall() {
      if (this.customSubUrl === "") {
        this.$message.error("Please fill in the required fields first to generate a subscription link");
        return false;
      }

      const url = "clash://install-config?url=";
      window.open(
        url +
          encodeURIComponent(
            this.customShortSubUrl !== ""
              ? this.customShortSubUrl
              : this.customSubUrl
          )
      );
    },
    downloadFile() {
      if (this.form.sourceSubUrl === "" || this.form.clientType === "") {
        this.$message.error("Subscription link and client are required");
        return false;
      }

      let backend =
        this.form.customBackend === ""
          ? defaultBackend
          : this.form.customBackend;

      let sourceSub = this.form.sourceSubUrl;
      sourceSub = sourceSub.replace(/(\n|\r|\n\r)/g, "|");

      this.linkFile =
        backend +
        "target=" +
        this.form.clientType +
        "&url=" +
        encodeURIComponent(sourceSub) +
        "&insert=" +
        this.form.insert;

        if (this.form.remoteConfig !== "") {
          this.linkFile +=
            "&config=" + encodeURIComponent(this.form.remoteConfig);
        }
        if (this.form.excludeRemarks !== "") {
          this.linkFile +=
            "&exclude=" + encodeURIComponent(this.form.excludeRemarks);
        }
        if (this.form.includeRemarks !== "") {
          this.linkFile +=
            "&include=" + encodeURIComponent(this.form.includeRemarks);
        }
        if (this.form.filename !== "") {
          this.linkFile +=
            "&filename=" + encodeURIComponent(this.form.filename);
        }
        if (this.form.rename !== "") {
          this.linkFile +=
            "&rename=" + encodeURIComponent(this.form.rename);
        }
        if (this.form.appendType) {
          this.linkFile +=
            "&append_type=" + this.form.appendType.toString();
        }

        this.linkFile +=
          "&emoji=" +
          this.form.emoji.toString() +
          "&list=" +
          this.form.nodeList.toString() +
          "&udp=" +
          this.form.udp.toString() +
          "&tfo=" +
          this.form.tfo.toString() +
          "&expand=" +
          this.form.expand.toString() +
          "&scv=" +
          this.form.scv.toString() +
          "&fdn=" +
          this.form.fdn.toString() +
          "&sort=" +
          this.form.sort.toString();

        if (this.form.clientType === "clash") {
          if (this.form.tpl.clash.doh === true) {
            this.linkFile += "&clash.doh=true";
          }

          this.linkFile += "&new_name=" + this.form.new_name.toString();
        }
      
      window.open(this.linkFile);
      window.close(this.linkFile);
    },
    makeUrl() {
      if (this.form.sourceSubUrl === "" || this.form.clientType === "") {
        this.$message.error("Subscription link and client are required");
        return false;
      }
      if (this.form.sourceSubUrl.indexOf("losadhwse") !== -1 && (this.form.customBackend.indexOf("api.wcc.best") !== -1)) {
        this.$alert("Fries has blocked the backend, please change to another backend to convert!",{
        type: "warning",
        confirmButtonText: 'Sure',
        customClass: 'msgbox',
        showClose: false ,
        });
        return false;
      }

      let backend =
        this.form.customBackend === ""
          ? defaultBackend
          : this.form.customBackend;

      let sourceSub = this.form.sourceSubUrl;
      sourceSub = sourceSub.replace(/(\n|\r|\n\r)/g, "|");

      this.customSubUrl =
        backend +
        "target=" +
        this.form.clientType +
        "&url=" +
        encodeURIComponent(sourceSub) +
        "&insert=" +
        this.form.insert;


        if (this.form.remoteConfig !== "") {
          this.customSubUrl +=
            "&config=" + encodeURIComponent(this.form.remoteConfig);
        }
        if (this.form.excludeRemarks !== "") {
          this.customSubUrl +=
            "&exclude=" + encodeURIComponent(this.form.excludeRemarks);
        }
        if (this.form.includeRemarks !== "") {
          this.customSubUrl +=
            "&include=" + encodeURIComponent(this.form.includeRemarks);
        }
        if (this.form.filename !== "") {
          this.customSubUrl +=
            "&filename=" + encodeURIComponent(this.form.filename);
        }
        if (this.form.rename !== "") {
          this.customSubUrl +=
            "&rename=" + encodeURIComponent(this.form.rename);
        }
        if (this.form.appendType) {
          this.customSubUrl +=
            "&append_type=" + this.form.appendType.toString();
        }
        
        this.customSubUrl +=
          "&emoji=" +
          this.form.emoji.toString() +
          "&list=" +
          this.form.nodeList.toString() +
          "&udp=" +
          this.form.udp.toString() +
          "&tfo=" +
          this.form.tfo.toString() +
          "&expand=" +
          this.form.expand.toString() +
          "&scv=" +
          this.form.scv.toString() +
          "&fdn=" +
          this.form.fdn.toString() +
          "&sort=" +
          this.form.sort.toString();

        if (this.form.tpl.surge.doh === true) {
          this.customSubUrl += "&surge.doh=true";
        }

        if (this.form.clientType === "clash") {
          if (this.form.tpl.clash.doh === true) {
            this.customSubUrl += "&clash.doh=true";
          }

          this.customSubUrl += "&new_name=" + this.form.new_name.toString();
        }

      window.open(this.customSubUrl);
    },
    makeShortUrl() {
      if (this.customSubUrl === "") {
        this.$message.warning("Generate link panjang dulu baru bikin link pendek");
        return false;
      }
      
      let duan =
        this.form.shortType === ""
          ? shortUrlBackend
          : this.form.shortType;
      
      this.loading = true;

      let data = new FormData();
      data.append("longUrl", btoa(this.customSubUrl));

      this.$axios
        .post(duan, data, {
          header: {
            "Content-Type": "application/form-data; charset=utf-8"
          }
        })
        .then(res => {
          if (res.data.Code === 1 && res.data.ShortUrl !== "") {
            this.customShortSubUrl = res.data.ShortUrl;
            this.$copyText(res.data.ShortUrl);
            this.$message.success("Link pendek sudah di copy ke clipboard silakan buka di tab browser baru untuk melihat config atau bisa langsung di gunakan di app sebagai url config");
          } else {
            this.$message.error("Gagal mendapatkan link pendek：" + res.data.Message);
          }
        })
        .catch(() => {
          this.$message.error("Gagal mendapatkan link pendek");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    confirmUploadConfig() {
      if (this.uploadConfig === "") {
        this.$message.warning("Jenis config tidak boleh kosong");
        return false;
      }

      this.loading = true;

      let data = new FormData();
      data.append("password", this.uploadPassword);
      data.append("config", this.uploadConfig);

      this.$axios
        .post(configUploadBackend, data, {
          header: {
            "Content-Type": "application/form-data; charset=utf-8"
          }
        })
        .then(res => {
          if (res.data.code === 0 && res.data.data.url !== "") {
            this.$message.success(
              "Link remote upload configuration sudah di copy ke clipboard paste di notepad untuk disimpan (bisa digunakan kembali tanpa perlu upload config lagi)...link file berlaku selama 3 bulan"
            );

            // Automatically fill in "Form-Remote Configuration"
            this.form.remoteConfig = res.data.data.url;
            this.$copyText(this.form.remoteConfig);

            this.dialogUploadConfigVisible = false;
          } else {
            this.$message.error("Upload remote configuration gagal：" + res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error("Upload remote configuration gagal");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    renderPost() {
      let data = new FormData();
      data.append("target",encodeURIComponent(this.form.clientType));
      data.append("url",encodeURIComponent(this.form.sourceSubUrl));
      data.append("config",encodeURIComponent(this.form.remoteConfig));
      data.append("exclude",encodeURIComponent(this.form.excludeRemarks));
      data.append("include",encodeURIComponent(this.form.includeRemarks));
      data.append("rename",encodeURIComponent(this.form.rename));
      data.append("tls13",encodeURIComponent(this.form.tls13.toString()));
      data.append("surgeForce",encodeURIComponent(this.form.surgeForce.toString()));
      data.append("emoji",encodeURIComponent(this.form.emoji.toString()));
      data.append("list",encodeURIComponent(this.form.nodeList.toString()));
      data.append("udp",encodeURIComponent(this.form.udp.toString()));
      data.append("tfo",encodeURIComponent(this.form.tfo.toString()));
      data.append("expand",encodeURIComponent(this.form.expand.toString()));
      data.append("scv",encodeURIComponent(this.form.scv.toString()));
      data.append("fdn",encodeURIComponent(this.form.fdn.toString()));
      data.append("sdoh",encodeURIComponent(this.form.tpl.surge.doh.toString()));
      data.append("cdoh",encodeURIComponent(this.form.tpl.clash.doh.toString()));
      data.append("newname",encodeURIComponent(this.form.new_name.toString()));
      return data;
    },
  }
};
</script>
<style>
.el-form-item__label{
 font-size: 12px!important;
}
</style>